import { Menu } from '@headlessui/react';
import { ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import type { ReactNode } from 'react';

export type DropdownOption = {
  name: ReactNode;
  action: () => void;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  danger?: boolean;
};

export interface DropdownProps {
  id?: string;
  label?: string;
  options: DropdownOption[][];
}

export const Dropdown: React.FC<DropdownProps> = ({ id, label, options }) => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      {() => (
        <>
          <Menu.Button
            type='button'
            data-test-id={`dropdown-${id}`}
            className={`border-light-gray shadow-light-drop flex items-center justify-center rounded-lg border bg-white hover:brightness-100 brightness-95 px-4 py-2 text-sm text-gray-600 ${
              !label ? 'h-9 w-9' : ''
            }`}
          >
            {label ? (
              <>
                {label}
                <ChevronDownIcon
                  className='text-primary ml-2 -mr-1 h-5 w-5 flex-none hover:text-amber-500'
                  aria-hidden='true'
                />
              </>
            ) : (
              <EllipsisVerticalIcon className='h-4 w-4 flex-none text-gray-400' />
            )}
          </Menu.Button>

          <Menu.Items
            // static

            className='absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          >
            {/* <ThemeTransition>
              <div> */}
            {options
              // Filter because they may be arrays with no elements
              .filter(op => op.length !== 0)
              .map((o, index) => (
                <div className='px-1 py-1' key={index}>
                  {o.map(({ disabled, action, icon: Icon, name, danger }, index) => (
                    <Menu.Item key={index} disabled={disabled}>
                      {({ active }) => (
                        <button
                          className={`file:group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-600 disabled:opacity-50`}
                          onClick={action}
                          style={{
                            backgroundColor: active ? '#3b82f6' : 'white',
                            // eslint-disable-next-line no-nested-ternary
                            color: active ? 'white' : danger ? 'red' : 'inherit',
                          }}
                          disabled={disabled}
                          data-test-id={`dropdown-${id ? id : 'unknown'}-options-${
                            typeof name === 'string' ? name : 'unknown'
                          }`}
                        >
                          {Icon && (
                            <Icon
                              className={`${
                                active ? ' text-gray-100' : `${danger ? 'text-danger-color' : 'text-gray-400'}`
                              } mr-2 h-5 w-5`}
                            />
                          )}
                          {name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              ))}
            {/* </div>
            </ThemeTransition> */}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};
