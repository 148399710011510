import type { DropdownProps } from './Dropdown';
import { Dropdown } from './Dropdown';

export interface FloatingButtonProps {
  dropDownProps?: DropdownProps;
  buttons: {
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    action: () => void;
  }[];
}

export const FloatingButtons: React.FC<FloatingButtonProps> = ({ buttons, dropDownProps }) => {
  return (
    <div className={`absolute flex gap-2 right-3 top-3 z-20 opacity-60 group-hover/item:opacity-100`}>
      {buttons.map((b, i) => (
        <button
          key={i}
          className='border-light-gray shadow-light-drop flex items-center justify-center rounded-lg border bg-white hover:brightness-100 brightness-95 px-2 py-2 text-gray-600'
          onClick={b.action}
        >
          <b.icon className='h-5 w-5' />
        </button>
      ))}
      {dropDownProps && <Dropdown {...dropDownProps} />}
    </div>
  );
};
